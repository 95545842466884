<template>
	<div>
		<el-button type="primary" @click="add">添加</el-button>
		<e-table @query-Table-data="handleCurrentChange" ref="tables" :tableCols="addressTableConf" :dataOrigin="dataOrigin">
			<template #1="scope">
				<el-tag v-if="scope.rowData['is_default'] == 1">默认地址</el-tag>
			</template>
			<template #operation="scope">
				<el-button type="primary" @click="edit(scope.rowData.id)">编辑</el-button>
				<el-button type="danger"  @click="del(scope.rowData.id)">删除</el-button>
			</template>
		</e-table>
		<e-dialog :title="isAdd ? '新增合同' : '编辑合同'" width="500px" ref="dialogRef" v-on:confirm="confirm">
			<el-form label-width="auto">
				<el-form-item label="收货人姓名">
					<el-input v-model="form.username"></el-input>
				</el-form-item>
				<el-form-item label="选择城市">
					<el-cascader
							size="large"
							:options="options"
							v-model="selectedOptions"
							@change="handleChange">
					</el-cascader>
				</el-form-item>
				<el-form-item label="收件地址">
					<el-input v-model="form.address"></el-input>
				</el-form-item>
				<el-form-item label="联系方式">
					<el-input v-model="form.mobile"></el-input>
				</el-form-item>
				<el-form-item label="设为默认地址">
					<el-switch
						v-model="is_default"
						active-text=""
						inactive-text="">
						</el-switch>
				</el-form-item>
			</el-form>
		</e-dialog>
	</div>
</template>

<script>
import {addressTableConf} from '@data/system/address'
import { regionData } from 'element-china-area-data'
import provinceAndCity from '../../common/province.json'
export default {
	name: 'Address',
	data() {
		return {
			addressTableConf,
			isAdd: true,
			dataOrigin: {
				data: []
			},
			form: {},
			activeName: "first",
			currentPage: 1,
			pageSize: 10,
			total: 0,
			is_default: false,
			options: regionData,
			selectedOptions: [],
			search: {},
			id: ''
		}
	},
	methods: {
		handleCurrentChange(data){
			this.$refs.tables.loading = true
			this.currentPage = data.currentPage
			this.GetLists()
		},
		add(){
			this.id = ''
			this.form = {}
			this.selectedOptions = []
			this.$refs.dialogRef.handleOpen();
			this.isAdd = true;
		},
		edit(id){
			let that = this
			that.form = {}
			that.is_default = false
			this.selectedOptions = []
			that.$refs.dialogRef.handleOpen()
			this.id = id
			this.isAdd = false
			let loading = this.$loadings.service({
				'text': "请求中",
				background: 'rgba(28,28,28,0.7)',
			})
			that.get('?server=live.address.item.get',{id: id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data)
				that.form = response.data
				that.form.username = response.data.username
				that.selectedOptions = response.data.code
				that.form.is_default = response.data.is_default
				that.form.address = response.data.address
				if(that.form.is_default == '1'){
					that.is_default = true
				}

				loading.close()
			}).catch(function (error) {
				loading.close()
				that.$message.error('请求超时');
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		handleChange (value) {
			this.search.province = ""
			this.search.city = ""
			this.search.district = ""
			for (var k = 0, lengthk = provinceAndCity.length; k < lengthk; k++) {
				//确定省
				if(provinceAndCity[k].code == value[0]){
					this.search.province = provinceAndCity[k].name;
					if(provinceAndCity[k].cityList && value.length>=2 && value[1]!=""){
						for (var i = 0, lengthi = provinceAndCity[k].cityList.length; i < lengthi; i++){
							//确定市
							if(provinceAndCity[k].cityList[i].code == value[1] || provinceAndCity[k].cityList.length == 1 ){
								this.search.city = provinceAndCity[k].cityList[i].name;
								//确定区
								if(provinceAndCity[k].cityList[i].areaList && value.length==3 && value[2]!=""){
									for(var j = 0,lengthj = provinceAndCity[k].cityList[i].areaList.length ; j<lengthj;j++){
										if(provinceAndCity[k].cityList[i].areaList[j].code == value[2]){
											this.search.district = provinceAndCity[k].cityList[i].areaList[j].name;
											break;
										}
									}
								}
								break;
							}
						}
					}
					break;
				}
			}
		},
		del(id){
			let that = this
			this.$confirm('是否删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.address.del',{
					id:id}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message.success(response.data.msg);
					that.$refs.tables.loading = true
					that.GetLists()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});

		},
		handleClick() {
		},
		confirm(){
			let that = this
			this.post('?server=live.address.handle.post',{
				id: this.id,
				user:this.form.username,
				mobile: this.form.mobile,
				city: this.search.province+"/" + this.search.city+"/"+this.search.district,
				is_default: this.is_default,
				code: this.selectedOptions,
				address:this.form.address}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.$refs.tables.loading = true
				that.GetLists()
				that.$refs.dialogRef.handleClose()
				that.form = {}
				that.is_default = false
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetLists(){
			let that = this
			this.dataOrigin.data = []
			this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			that.$refs.tables && that.$refs.tables.initData()
			this.get('?server=live.address.lists.get',{types:this.activeName,
				page:this.currentPage,
				query:this.form.name,
				cat:this.form.cat,
				baotime:this.form.baotime}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				let len = response.data.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.data[i])
				}
				that.$refs.tables.loading = false
				// that.dataOrigin.data = response.data.data
				that.$refs.tables.total = response.data.total
				that.total = response.data.total
				that.$refs.tables.initData()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			this.$refs.tables.loading = true
		});
		this.GetLists()
	}
}
</script>
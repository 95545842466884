
export const addressTableConf = [
	{
		label: '默认地址',
		prop: '1'
	},
	{
		label: '收件人',
		prop: 'username'
	},
	{
		label: '联系电话',
		prop: 'mobile'
	},
	{
		label: '城市',
		prop: 'country'
	},
	{
		label: '联系地址',
		prop: 'address'
	},
	{
		label: '操作',
		prop: 'operation'
	}
]